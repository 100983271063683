@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
body{
  font-family: 'Roboto', sans-serif;}
.policy-form-container{
  padding-top: 3rem;
  margin: 0 auto;
  width: 80%;
  form{
    &>div{
      color: #333333;
      display: flex;
      flex-direction: column;
      label{
        margin-top: 20px;
        margin-bottom: 10px;
      }
      input{
        padding: 1rem;
        font-size: 1.2rem;
        display: inline-block;
        border-radius: 10px;
        border: 2px solid #cccccc;
        &:focus{outline: none}
      }
    }

    button{
      background: #095da7;
      color: #ffffff;
      padding: 1rem 4rem;
      font-size: 1.2rem;
      font-weight: bold;
      margin: 5rem auto 0 auto;
      border-radius: 10px;
      border: none;
      &:focus{outline: none;}
      &:active{background: #07467e;}
    }
  }
}

@media screen and (min-width: 600px){
  .policy-form-container{width: 40%}
}