
@import './fonts/index';
@import './colors';
@import './policy-form';

html, body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins-Regular';
}
.txt-light{
  font-family: 'Poppins-Light';
  font-weight: 300;
}
.txt-bold {
  font-family: 'Poppins-Bold';
  font-weight: '700';
}
.space-btn {
  margin-bottom: 20px;
}
.space-top {
  margin-top: 20px;
}
.row {
  display: flex;
  justify-content: space-between;
}

.xc-main {
    display: flex;
    border: 1px solid $border;
    min-width: 320px;
    max-width: 400px;
    min-height: 500px;
    margin: 10px auto;
    padding: 10px;
    flex-direction: column;
    font-family: 'Poppins-Regular';
    font-size: 14px;
    color: $gray;
}


.xc-header {
  width: 100%;
  height: 60px;
}

.xc-body {
  .row {
    p {
      margin: 0;
      width: 50%;
      word-break: break-all;
      &.title{
        width: 60%;
      }
    }
  }
  .p-2{padding-right: 20px;}
  .width-50{width: 50%;}

  .notice {
    font-size: 10px;
    font-style: italic;
    text-align: justify;
    word-break: keep-all;
    @extend .space-top;
  }
}
.static-section{
  .value{margin-left: 1.2em}
}

@import './loader';
@import './feedback';

:export { XColor:$xc };