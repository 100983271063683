@font-face {
  font-family: 'Poppins-Regular';
  font-weight: '400';
  src: url('./Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  font-weight: '700';
  src: url('./Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Light';
  font-weight: '300';
  src: url('./Poppins/Poppins-Light.ttf') format('truetype');
}
